import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../services/api'
import VuexPersistence from 'vuex-persist'
import jQuery from 'jquery'

//import Cookies from 'js-cookie'

// import axios from 'axios'

Vue.use(Vuex);

/*
const vCookie = new VuexPersistence({
    storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 4, secure: false }),
        removeItem: key => Cookies.remove(key)
    }
})
*/

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

function getValue (key) {

    if (typeof (Storage) !== "undefined") {

        var post = localStorage.getItem(key);
        if (post == "undefined") {
            return '';
        }

        return post;
    } else {
        return getCookie(key);
    }
}

function getCookie (ckey) {

    var key = ckey + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {

            c = c.substring(1);

        }
        if (c.indexOf(key) == 0) {

            return c.substring(key.length, c.length);
        }
    }
    return '';
}

function getObject (key) {

    if (typeof (Storage) !== "undefined") {

        var post = localStorage.getItem(key);

        if (post == "undefined") {

            return false;
        }

        return JSON.parse(post);
    } else {

        return JSON.parse(this.getCookie(key));

    }
}

function getBetSlip (stake) {
    var bSlip = getObject("bslip");

    if (!bSlip || bSlip === '' || bSlip === "") {

        bSlip = [];
    }

    var total = bSlip.length;

    if (total === undefined) {

        total = Object.keys(bSlip).length
    }

    var bs = {};
    var totalOdds = parseFloat(1);

    if (total > 0) {

        jQuery.each(bSlip, function (k, v) {

            bs[v.match_id] = v;
            var odds = parseFloat(v.odd);
            totalOdds = totalOdds * odds;

        })
    }

    // get total odds

    if (stake === 0) {

        stake = getValue("stake");
    }

    if (parseInt(stake) === 0) {

        // stake = 50;

    }

    // var stake = stakeBeforeTax / 1.2;
    var winning = stake * totalOdds;

    if (winning > 1000500) {

        winning = 1000500;
    }

    var netWinning = winning - stake;
    var withholdingTax = netWinning * 0.2;
    var payout = winning - withholdingTax;
    var wage_tax = 7.5;
    // var stake_after_tax = (stake * 100)/ (100+wage_tax);

    var stake_tax = stake - ((stake * 100)/ (100+wage_tax));

    var res = {};
    res.picks = bs;
    res.payout = payout;
    res.stake = stake;
    res.winning = winning;
    res.tax = withholdingTax;
    res.total = total;
    res.stake_tax = stake_tax;
    res.odds = parseFloat(totalOdds).toFixed(3);
    return res;
}

function saveObject (key, value) {

    value = JSON.stringify(value);

    if (typeof (Storage) !== "undefined") {

        localStorage.setItem(key, value);
    } else {

        document.cookie = key + "=" + value;
    }
}

function hasSuspendedPicks (){

    var bSlip = getObject("bslip");

    if (!bSlip || bSlip === '' || bSlip === "") {

        bSlip = [];
    }

    var suspended = 0;

    jQuery.each(bSlip, function (k, v) {

        if (v !== undefined && v !== null) {

            //a suspended/deactivated outcome has active = 0 and status > 0
            if(parseInt(v.active) !== 1 || parseInt(v.status) !== 0 ) {

                suspended++
            }
        }
    });

    return suspended > 0;
}

const state = {
    sports: [], // all sports
    sport_id: 0, // selected sportID
    sport_name: 'Soccer',
    competition_id: 0, // selected competitionID
    market_id: 0, // selected marketID
    country_code: "", // selected marketID
    leagues: [], // selected leagues
    placeBet:0,
    placeHolderMatches: [],
    games: {
        current_page: 0,
        pending_page: 0,
        last_page: 0,
        from: 0,
        total: 0,
        left_records: 0,
        data:[]
    },
    live_games: {
        current_page: 0,
        last_page: 0,
        from: 0,
        total: 0,
        left_records: 0,
        data:[]
    },
    upcoming_live_games: {
        current_page: 0,
        last_page: 0,
        from: 0,
        total: 0,
        left_records: 0,
        data:[]
    },
    esports: {
        current_page: 0,
        last_page: 0,
        from: 0,
        total: 0,
        left_records: 0,
        data:[]
    },
    market_outcome: ['1','X','2'],
    markets: [],
    page: 0,
    busy: false,
    betslip: {
        picks: [],
        total: 0,
        payout: 0,
        winning: 0,
        tax: 0,
        odds: 0,
        stake: 0,
        stake_tax: 0
    },
    outrightbetslip: {
        picks: [],
        total: 0,
        payout: 0,
        winning: 0,
        tax: 0,
        odds: 0,
        stake: 0,
        stake_tax: 0
    },
    jackpotbetslip: {
        picks: [],
        total: 0,
        payout: 0,
        winning: 0,
        tax: 0,
        odds: 0,
        stake: 0,
        stake_tax: 0
    },
    has_suspended_picks: false,
    has_suspended_outright_picks: false,
    has_suspended_jackpot_picks: false,
    market_groups: [],
    error: [],
    success: [],
    warning: [],
    current_page: '',
    current_sub_page: '',
    games_filter: {
        highlights: 1,
        upcoming: 0,
        hours: -1,
        country_code: "",
        date: "",
        keyword:"",
        per_page: 20
    },
    default_games_filter: {
        highlights: 1,
        upcoming: 0,
        hours: -1,
        country_code: "",
        date: "",
        keyword:"",
        per_page: 20
    },
    top_competitions: [],
    freebet: {},
    bets: {},
    live_match_counter: 0,
    has_fixture: [],
    is_visible: [],
    is_busy:[],
    upcoming_date: '',
    hour: -1,
    fixture: {},
    app: false,
    show_app_banner: true,
    current_tab: 'highlights',
    walletBalance: 0,
    bonusBalance: 0,
    profile: {}
};

export default new Vuex.Store({
    state,
    getters: {
        profile: function(state){
            return state.profile;
        },
        walletBalance: function(state){
            return state.walletBalance;
        },
        bonusBalance: function(state){
            return state.bonusBalance;
        },
        place_bet: function(state){
            return state.placeBet;
        },
        get_place_holder_matches: function (state) {
            return state.placeHolderMatches;
        },
        has_suspended_picks:  function (state){

            return state.has_suspended_picks;

        },
        app:  function (state){

            return state.app;

        },
        has_suspended_outright_picks:  function (state){

            return state.has_suspended_outright_picks;

        },
        has_suspended_jackpot_picks:  function (state){

            return state.has_suspended_jackpot_picks;

        },
        hour:  function (state){

            return state.hour;

        },
        upcoming_date: function (state){

            return state.upcoming_date;

        },
        has_fixture: function (state){

            return state.has_fixture
        },
        is_visible: function (state){

            return state.is_visible
        },
        is_busy: function (state){

            return state.is_busy
        },
        freebet: function (state){
            return state.freebet
        },
        error: function (state){
            return state.error
        },
        success: function (state){
            return state.success
        },
        warning: function (state){
            return state.warning
        },
        sports: function(state) {

            return state.sports
        },
        leagues: function(state){

            return state.leagues
        },
        games: function(state){

            return state.games
        },
        live_games: function(state){

            return state.live_games
        },
        upcoming_live_games: function(state){

            return state.upcoming_live_games
        },
        markets: function(state){

            return state.markets
        },
        sport_id: function(state){

            return state.sport_id
        },
        sport_name: function(state){

            return state.sport_name
        },
        country_code: function(state){

            return state.country_code
        },
        competition_id: function(state){

            return state.competition_id
        },
        market_outcome: function(state){

            return state.market_outcome
        },
        market_id: function (state) {

            return state.market_id;
        },
        betslip: function (state) {

            return state.betslip;
        },
        outrightbetslip: function (state) {

            return state.outrightbetslip;
        },
        jackpotbetslip: function (state) {

            return state.jackpotbetslip;
        },
        market_groups: function (state) {

            return state.market_groups;
        },
        current_page: function (state) {

            return state.current_page;
        },
        current_sub_page: function (state) {

            return state.current_sub_page;
        },
        games_filter: function (state) {

            return state.games_filter;
        },
        default_games_filter: function (state) {

            return state.default_games_filter;
        },
        top_competitions: function (state) {

            return state.top_competitions;
        },
        countries: function (state) {

            return state.countries;
        },
        fixture: function (state) {

            return state.fixture;
        },
        bets: function (state) {

            return state.bets;
        },
        live_match_counter: function (state) {

            return state.live_match_counter;
        },
        esports: function (state) {

            return state.esports;
        },
    },
    mutations: {
        setProfile: function(state, value){
            state.profile = value;
        },
        setPlaceHolderMatches: function(state, value){
            state.placeHolderMatches = value;
        },
        setWalletBalance: function(state, value){
            state.walletBalance = value;
        },
        setBonusBalance: function(state, value){
            state.bonusBalance = value;
        },
        setPlaceBet: function(state, value){
            state.placeBet = value;
        },
        setCurrentTab: function(state, value){
            state.current_tab = value;
        },
        setAppBanner: function(state, value){
            state.show_app_banner = value;
        },
        setApp: function(state,app){

            state.app = app

        },
        setHasSuspendedPicks: function(state,has_suspended_picks){

            state.has_suspended_picks = has_suspended_picks

        },
        setHasSuspendedOutrightPicks: function(state,has_suspended_outright_picks){

            state.has_suspended_outright_picks = has_suspended_outright_picks

        },
        setHasSuspendedJackpotPicks: function(state,has_suspended_jackpot_picks){

            state.has_suspended_jackpot_picks = has_suspended_jackpot_picks

        },
        setHour: function(state,hour){

            state.hour = hour

        },
        setUpcomingDate: function(state,upcoming_date){

            state.upcoming_date = upcoming_date

        },
        setHasFixture: function(state,has_fixture){

            state.has_fixture = has_fixture

        },
        setIsVisible: function(state,is_visible){

            state.is_visible = is_visible

        },
        setIsBusy: function(state,is_busy){

            state.is_busy = is_busy

        },
        setFreebet: function(state,freebet){

            state.freebet = freebet;
        },
        setLiveMatchCounter: function(state,payload){

            //payload.arrival_time = new Date().getTime(); // in milisecconds
            state.live_match_counter = payload;
        },
        setError: function(state,payload){

            //payload.arrival_time = new Date().getTime(); // in milisecconds
            state.error = payload;
        },
        setSuccess: function(state,payload){

            state.success = payload;

        },
        setWarning: function(state,payload){

            state.warning = payload; //.push(payload);

        },
        resetAlerts: function(state){

            state.error = [];
            state.warning = [];
            state.success = [];
        },
        setCountryCode: function(state,country_code){

            state.country_code = country_code
        },
        setSports: function(state,payload){

            state.sports = payload
        },
        setSportID: function(state,sport_id){

            state.sport_id = sport_id
        },
        setSportName: function(state,sport_name){

            state.sport_name = sport_name
        },
        setLeague: function(state,payload){

            state.leagues = payload
        },
        setCompetitionID: function(state,competition_id){

            state.competition_id = competition_id
        },
        setMarket: function(state,payload){

            state.markets = payload
        },
        setMarketID: function(state,market_id){

            state.market_id = market_id;
        },
        setOutcomes: function(state,outcomes){

            state.market_outcome = outcomes
        },
        setGames: function(state,games) {

            state.games = games;
        },
        setLiveGames: function(state,live_games) {

            state.live_games = live_games;
        },
        setUpcomingLiveGames: function(state,upcoming_live_games) {

            state.upcoming_live_games = upcoming_live_games;
        },
        setEsports: function(state,games) {

            state.esports = games;
        },
        setBusy: function(state,busy){

            state.busy = busy
        },
        resetGames: function(state){

            state.games.current_page = 0;
            state.games.last_page = 0;
            state.games.total = 0;
            state.games.data = [];
            state.games.left_records = 0;

        },
        resetAllGames: function(state,sport_id){

            state.games.current_page = 0;
            state.games.last_page = 0;
            state.games.total = 0;
            state.games.data = [];
            state.games.left_records = 0;
            state.games_filter = {
                highlights: 1,
                upcoming: 0,
                hours: -1,
                country_code: "",
                date: "",
                keyword:"",
                per_page: 20
            };

            state.sport_id = sport_id;
            state.market_id = 1;
            state.competition_id = 0;
            state.current_sub_page = 'highlights';

        },
        resetUpcomingLiveGames: function(state,sport_id){

            state.upcoming_live_games = {
                highlights: 1,
                upcoming: 0,
                hours: -1,
                country_code: "",
                date: "",
                keyword:"",
                per_page: 20
            };
            state.upcoming_live_games.current_page = 0;
            state.upcoming_live_games.last_page = 0;
            state.upcoming_live_games.total = 0;
            state.upcoming_live_games.data = [];
            state.upcoming_live_games.left_records = 0;

            state.sport_id = sport_id;
            state.market_id = 1;
            state.competition_id = 0;
            state.current_sub_page = 'highlights';

        },
        setBetSlip: function(state,bestlip){

            state.betslip = bestlip
        },
        setOutrightBetSlip: function(state,outrightbetslip){

            state.outrightbetslip = outrightbetslip
        },
        setJackpotBetSlip: function(state,jackpotbetslip){

            state.jackpotbetslip = jackpotbetslip
        },
        setMarketGroups: function(state,market_groups){

            state.market_groups = market_groups
        },
        setCurrentPage: function(state,current_page){

            state.current_page = current_page
        },
        setCurrentSubPage: function(state,current_sub_page){

            state.current_sub_page = current_sub_page
        },
        // games filter
        setGamesFilter: function(state,games_filter){

            state.games_filter = games_filter
        },
        setCountries: function(state,countries){

            state.countries = countries
        },
        setTopCompetitions: function(state,top_competitions){

            state.top_competitions = top_competitions
        },
        setBets: function(state,bets){

            state.bets = bets
        },
        setFixture: function(state,fixture){

            state.fixture = fixture
        },
    },
    actions: {
        setProfile: function({commit}, value){
            commit('setProfile', value);
        },
        setWalletBalance: function({commit}, value){
            commit('setWalletBalance', value);
        },
        setBonusBalance: function({commit}, value){
            commit('setBonusBalance', value);
        },
        setPlaceBet: function({commit}, value){
            commit('setPlaceBet', value);
        },
        setAppBanner: function({commit}, value){
            commit('setAppBanner', value);
        },
        setCurrentTab: function({commit}, value){
            commit('setCurrentTab', value);
        },
        setApp: function({commit},app){

            commit('setApp',app)

        },
        setCommitPlaceHolderMatches: function({commit}, value){
            commit('setPlaceHolderMatches',value)
        },
        setHasSuspendedPicks: function({commit},has_suspended_picks){

            commit('setHasSuspendedPicks',has_suspended_picks)

        },
        setHasSuspendedOutrightPicks: function({commit},has_suspended_outright_picks){

            commit('setHasSuspendedOutrightPicks',has_suspended_outright_picks)

        },
        setHasSuspendedJackpotPicks: function({commit},has_suspended_jackpot_picks){

            commit('setHasSuspendedJackpotPicks',has_suspended_jackpot_picks)

        },
        setUpcomingDate: function({commit},upcoming_date){

            commit('setUpcomingDate',upcoming_date)

        },
        setHour: function({commit},hour){
            commit('setHour',hour)
        },
        setHasFixture: function({commit},has_fixture){
            commit('setHasFixture',has_fixture)
        },
        setIsVisible: function({commit},is_visible){
            commit('setIsVisible',is_visible)
        },
        setIsBusy: function({commit},is_busy){
            commit('setIsBusy',is_busy)
        },
        setFixture: function({commit},fixture){
            commit('setFixture',fixture)
        },
        getFixture: function ({commit},game_id) {

            if (this.state.busy) {

                console.log('AM currently busy ');
                //return;
            }

            var vm = this;
            var path = process.env.VUE_APP_URL_MORE_MARKETS_URL;
            path = path.replace("{game_id}",game_id);

            commit('setBusy',true)

            axios.post(path, JSON.stringify({game_id: game_id}))
                .then(res => {

                    commit('setBusy',false)
                    var fixture = res.data.message;

                    fixture.live = parseInt(fixture.producer_id) === 1;

                    // format over/under

                    var formatted_18 = {}
                    var formatted_238 = {}

                    jQuery.each(fixture.market,function(k,v) {

                        if(parseInt(v.market_id) === 18 ) {

                            jQuery.each(v.outcome,function(k1,v1){

                                var outcome_id = v1.outcome_id;
                                var specifier = v1.specifier; // 0.5.1.5,2.5,3.5,4.5
                                var over = "over";
                                var under = "under";

                                //chck if key does not exist and create it
                                if(formatted_18[specifier] === null || !formatted_18[specifier] || formatted_18[specifier] === false || formatted_18[specifier] === undefined ) {

                                    formatted_18[specifier] = {};
                                    formatted_18[specifier][over] = {};
                                    formatted_18[specifier][under] = {};

                                }

                                if(parseInt(outcome_id) === 13 ) {

                                    formatted_18[specifier][under] = v1;

                                }

                                if(parseInt(outcome_id) === 12 ) {

                                    formatted_18[specifier][over] = v1;

                                }

                            })

                        }

                        else if(parseInt(v.market_id) === 238 ) {

                            jQuery.each(v.outcome,function(k1,v1){

                                var outcome_id = v1.outcome_id;
                                var specifier = v1.specifier;
                                var over = "over";
                                var under = "under";

                                if(formatted_238[specifier] === null || !formatted_238[specifier] || formatted_238[specifier] === false || formatted_238[specifier] === undefined ) {

                                    formatted_238[specifier] = {};
                                    formatted_238[specifier][over] = {};
                                    formatted_238[specifier][under] = {};

                                }

                                if(parseInt(outcome_id) === 13 ) {

                                    formatted_238[specifier][under] = v1;

                                }

                                if(parseInt(outcome_id) === 12 ) {

                                    formatted_238[specifier][over] = v1;

                                }

                            })

                        }

                    });

                    fixture.totals_18 = formatted_18;
                    fixture.totals_238 = formatted_238;

                    if(fixture.event_time.length > 0 ) {

                        var p = vm.fixture.event_time.split(':');
                        var minutes = p[0];
                        var seconds = p[1];
                        seconds = parseInt(seconds);
                        seconds = seconds + parseInt(minutes) * 60;
                        fixture.seconds = seconds;
                    }

                    commit('setFixture',fixture);


                    setTimeout(function (){

                        jQuery(".picked").removeClass('picked');
                        var betsObject = getBetSlip(0);
                        var bets = betsObject.picks;

                        jQuery.each(bets, function (matchID, b) {

                            var oddsClass = "#" + b.odd_id;
                            jQuery(oddsClass).addClass('picked');
                        });

                        saveObject("betslip", betsObject);

                        commit("setBetSlip",betsObject);
                        commit("setHasSuspendedPicks",hasSuspendedPicks());

                    },100)

                })
                .catch(error => {

                    commit('setBusy',false)
                    console.log(error)

                })
        },

        getSports: function ({commit}) {

            axios.post(process.env.VUE_APP_URL_SPORTS,JSON.stringify({}))
                .then(res => {

                    var sp = res.data.message;
                    commit('setSports',sp)

                })
                .catch(error => {

                    console.log(error)

                })
        },
        getLiveMatchCounter: function ({commit}) {

            axios.post(process.env.VUE_APP_URL_LIVE_MATCH_COUNTER,JSON.stringify({}))
                .then(res => {

                    var sp = res.data.message;
                    commit('setLiveMatchCounter',sp)

                })
                .catch(error => {

                    console.log(error)

                })
        },
        setLeague: function({commit},league){
            commit('setLeague',league)
        },
        getMarkets: function ({commit}) {

            var sport_id = this.state.sport_id;

            if(parseInt(sport_id) === 0 ) {

                return
            }

            var path = process.env.VUE_APP_URL_MARKET.replace("{market_id}",sport_id);
            axios.post(path,JSON.stringify({sport_id: sport_id}))
                .then(res => {

                    var leagues = res.data.message;
                    commit('setMarket',leagues)

                })
                .catch(error => {

                    console.log(error)

                })
        },
        getLeague: function ({commit}) {

            var sport_id = this.state.sport_id;

            if(parseInt(sport_id) === 0 ) {

                return
            }

            var path = process.env.VUE_APP_URL_TOP_COMPETITIONS.replace("{sport_id}",sport_id);
            path = path.replace("{count}",14);
            axios.post(path,JSON.stringify({sport_id: sport_id,count: 14}))
                .then(res => {

                    var leagues = res.data.message;
                    //console.log("LEAGUE-->"+JSON.stringify(leagues));
                    commit('setLeague',leagues)

                })
                .catch(error => {

                    console.log(error)

                })
        },
        setGamesFilter: function({commit},games_filter){

            commit('resetAllGames',this.state.sport_id)
            commit('setGamesFilter',games_filter)
        },
        setGames: function({commit},currentGames){

            commit('setGames',currentGames);
        },
        setFilter: function({commit},games_filter){

            commit('resetAllGames',this.state.sport_id)
            commit('setGamesFilter',games_filter)
        },
        getUpcomingLiveGames: function ({commit},force) {

            if (this.state.busy) {

                console.log('AM currently busy ');
                //return;
            }

            var currentGames = this.state.upcoming_live_games();
            var page = this.state.upcoming_live_games.current_page + 1;

            if(!force) {

                if (currentGames.current_page === currentGames.last_page && currentGames.current_page > 0) {

                    return
                }

            } else {

                page = 1;
            }

            commit('setBusy',true)

            var path = process.env.VUE_APP_URL_UPCOMING_LIVE_ODDS;
            path = path.replace("{sport_id}",this.state.sport_id);
            path = path.replace("{competition_id}",this.state.competition_id);
            path = path.replace("{market_id}",this.state.market_id);
            path = path.replace("{page}",page);

            var payload = this.state.games_filter;

            axios.post(path,JSON.stringify(payload))
                .then(res => {

                    commit('setBusy',false)
                    var games = res.data.message;

                    currentGames.total = games.total;
                    currentGames.current_page = games.current_page;
                    currentGames.from = games.from;
                    currentGames.last_page = games.last_page;
                    currentGames.left_records = games.left_records;
                    currentGames.data = games.data;

                    commit('setUpcomingLiveGames',currentGames);


                })
                .catch(error => {

                    commit('setBusy',false)
                    console.log(error)

                })
        },
        setCountryCode: function ({commit},country_code) {

            commit('setCountryCode',country_code)
        },

        setSportID: function ({commit},sport_id) {

            commit('setSportID',sport_id)

            //this.dispatch("getLeagues");
            //this.dispatch("getCountries");

            if(parseInt(sport_id) > 0 ) {

                //this.dispatch("getMarkets");

                switch (this.state.current_page) {

                    case "home":
                        //commit('resetGames')
                        commit('setSportID', sport_id)
                        break;

                    case "live":
                        commit('setSportID', sport_id)
                        break;
                }
            }
        },
        setSportName: function ({commit},sport_name) {

            commit('setSportName',sport_name)

        },
        setCompetitionID: function ({commit},competition_id) {
            commit('setCompetitionID',competition_id)
        },
        setMarketID: function ({commit},market_id) {

            if(this.state.current_page === "home" )
            {
                //commit('resetGames')
                commit('setMarketID',market_id)
            }

            if(this.state.current_page === "live") {

                commit('setMarketID',market_id)
            }
        },
        setOutcomes: function ({commit},outcomes) {

            commit('setOutcomes',outcomes)
        },
        resetGames: function ({commit}) {

            commit('resetGames')
        },
        resetUpcomingLiveGames: function ({commit}) {

            commit('resetUpcomingLiveGames');
            this.dispatch("setSportID",1);

        },
        resetAllGames: function ({commit}) {

            commit('resetAllGames');
            this.dispatch("setSportID",1);

        },
        setBetSlip: function ({commit},betslip) {

            commit('setBetSlip',betslip)
        },
        setOutrightBetSlip: function ({commit},outrightbetslip) {

            commit('setOutrightBetSlip',outrightbetslip)
        },
        setJackpotBetSlip: function ({commit},jackpotbetslip) {

            commit('setJackpotBetSlip',jackpotbetslip)
        },
        setError: function ({commit},payload) {

            payload.arrival_time = new Date().getTime(); // in milisecconds
            var all = state.error;
            all.push(payload);
            commit('setError',all)

        },
        removeError: function ({commit},index) {

            var all = state.error;
            all = all.splice(index);
            commit('setError',all)
        },
        setWarning: function ({commit},payload) {

            payload.arrival_time = new Date().getTime(); // in milisecconds
            var all = state.warning;
            all.push(payload);
            commit('setWarning',all)
        },
        removeWarning: function ({commit},index) {

            var all = state.warning;
            all = all.splice(index);
            commit('setWarning',all);
        },
        setSuccess: function ({commit},success) {

            success.arrival_time = new Date().getTime(); // in milisecconds
            var all_success = state.success;
            all_success.push(success);

            commit('setSuccess',all_success)
        },
        removeSuccess: function ({commit},index) {

            var all = state.success;
            all = all.splice(index);
            commit('setSuccess',all);
        },
        resetAlerts: function ({commit}) {

            commit('resetAlerts')
        },
        setCurrentPage: function ({commit},current_page) {

            commit('setCurrentPage',current_page)
        },
        setCurrentSubPage: function ({commit},current_page) {

            commit('setCurrentSubPage',current_page)
        },
        setFreebet: function ({commit},fixture) {

            commit('setFreebet',fixture)
        },
        setBets: function ({commit},bets) {

            commit('setBets',bets)
        },
    },
    plugins: [vuexLocal.plugin]
})